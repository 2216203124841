import Button from "common/components/Button";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import React from "react";

const PotwierdzeniePlatnosci = () => (
  <DefaultLayout>
    <SEO title="Potwierdzenie płatności" />

    <div className="mx-auto mt-12 max-w-xs text-center text-xl sm:max-w-sm md:max-w-xl md:text-2xl">
      <div className="mb-6 text-7xl sm:text-8xl md:text-9xl">🎉</div>

      <h1 className="text-3xl font-semibold text-indigo-600 md:mt-4 md:text-4xl">
        Dziękujemy za dokonanie zakupu!
      </h1>

      <p className="mt-4">
        Płatność przebiegła pomyślnie. Potwierdzenie zamówienia zostanie wkrótce
        przesłane na podany adres mailowy :)
      </p>

      <Button customClasses="inline-block mt-6" outlined to="/">
        Powrót do strony głównej
      </Button>

      <p className="mt-8 text-xs text-zinc-600">
        W razie braku otrzymania potwierdzenia lub chęci zadania dodatkowych
        pytań prosimy o kontakt mailowy lub telefoniczny
      </p>
    </div>
  </DefaultLayout>
);

export default PotwierdzeniePlatnosci;
